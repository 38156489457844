import React, { Fragment } from 'react';
import { Link } from '@inertiajs/react';
import { MapPin, ChevronDown } from 'lucide-react';
import { Button } from "@/Components/ui/button";
import { Menu, Transition } from '@headlessui/react';

interface Field {
    name: string;
    link: string;
}

interface HeroProps {
    fields: Field[];
}

const Hero: React.FC<HeroProps> = ({ fields }) => {
    return (
        <div className="relative h-[30rem] block w-full">
            {/* Background Image */}
            <img
                src="/images/bg.jpg"
                className="absolute inset-0 w-full h-full object-cover"
                alt="Background"
            />
            {/* Overlay for better text visibility */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            {/* Content */}
            <div className="relative z-10 h-full flex flex-col items-center justify-center text-center text-white">
                <h1 className="text-4xl w-1/2 md:text-6xl font-bold mb-8">
                    Freedom to <span className='text-brand indie-flower-regular'>Play</span><br />
                    Space to <span className='text-brand indie-flower-regular'>Grow</span>
                </h1>
                <Link href="/locations">
                    <Button size="lg" className="text-xl mt-6 hover:bg-dark bg-dark px-8 py-8">
                        <MapPin className='mr-4' />
                        Find Your Nearest Field
                    </Button>
                </Link>
                {/* New Book Now button with dropdown */}
                <Menu as="div" className="relative inline-block text-left mt-8">
                    {({ open }) => (
                        <>
                            <Menu.Button as={Button} size="lg" className="text-lg bg-brand text-white hover:bg-opacity-80 px-6 py-6">
                                Book Now
                                <ChevronDown className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items static className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1">
                                        {fields.map((field) => (
                                            <Menu.Item key={field.name + "#booking"}>
                                                {({ active }) => (
                                                    <Link
                                                        href={`${field.link}#booking`}
                                                        className={`${active ? 'bg-brand text-white' : 'text-gray-900'
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                    >
                                                        {field.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        ))}
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </>
                    )}
                </Menu>
            </div>
        </div>
    );
};

export default Hero;