import { Link, Head } from '@inertiajs/react';
import Header from '@/Components/Built/Website/Header';
import Hero from '@/Components/Built/Website/Hero';
import Callout from '@/Components/Built/Website/Callout';
import WhyUs from '@/Components/Built/Website/WhyUs';
import Testimonials from '@/Components/Built/Website/Testimonials';
import FieldsGrid from '@/Components/Built/Website/FieldsGrid';
import Footer from '@/Components/Built/Website/Footer';
import { PageProps } from '@/types';
import { User } from '@/types';

interface FieldProps {
    id: number;
    image: string;
    name: string;
    what3words: string;
    price: number;
    link: string;
    from: string;
}

interface FieldsPageProps {
    auth: {
        user?: User;
    };
    fields: FieldProps[];
}

const Home: React.FC<FieldsPageProps> = ({ fields, auth }) => {
    const user = auth.user;
    
    return (
        <>
            <Head title="Welcome" />
            <Header user={user} />
            <Hero fields={fields} />
            <Callout text="Situated in sites across southern England, all are in picturesque, rural locations
allowing you to relax while your doggys run" />
            <WhyUs />
            <FieldsGrid fields={fields} />
            <Testimonials />
            <Footer />
        </>
    );
}

export default Home;